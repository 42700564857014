import XLSX from 'xlsx';

export default function exportToJson(workbook, rules) {
  const out = {}
  const normSheetNames = workbook.SheetNames.map(name => strNormalize(name))
  // Find worksheet
  for (let rname of Object.keys(rules)) {
    const rule = rules[rname]
    rname = strNormalize(rname)
    // Worksheet name must begin with rule name to match
    const sheetIndex = normSheetNames.findIndex(name => {
      return name.substr(0, rname.length) === rname
    })

    // Sheet not found, continue
    if (sheetIndex < 0) {
      continue
    }

    // Select sheet
    const sheet = workbook.Sheets[workbook.SheetNames[sheetIndex]]

    // convert to object (json)
    const keys = rule.columns.map(col => col.key)
    let json = null
    if (rule.type === 'form') {
      const j = {}
      json = [j]
      for (let i = 0; i < rule.columns.length; i++) {
        const cell = sheet[`B${i+1}`]
        j[keys[i]] = cell ? cell.v : undefined
      }
    } else {
      json = XLSX.utils.sheet_to_json(sheet, {
        header: keys
      })
      // remove first line (header)
      json.splice(0, 1)
    }
    if (!json) {
      throw new Error('Unable to parse excel sheet')
    }

    // process all v
    for (let row of json) {
      const index = json.indexOf(row) + (rule.hasHeader ? 2 : 1)
      row._selected = false
      row._errors = []
      row._status = 'OK'
      for (let colRule of rule.columns) {
        try {
          row[colRule.key] = colRule.validate(row[colRule.key], row, index)
          if (typeof row[colRule.key] === 'string') {
            row[colRule.key] = row[colRule.key].trim()
          }
        } catch (e) {
          const err = new Error(e)
          err.row = 0
          err.key = colRule.key
          row._errors.push(err)
          row._status = 'E'
        }
      }

      // compute computed keys
      if (rule.computed) {
        for (let ckey of Object.keys(rule.computed)) {
          row[ckey] = rule.computed[ckey](row)
        }
      }
    }

    out[rname] = json
    if (rule.type === 'form') {
      out[rname] = json[0]
    }
  }
  return out
}

function strNormalize(str) {
  return str.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")
}
