export const compileInRules = (rules) => {
  const compiled = {}
  for (let sheetName of Object.keys(rules)) {
    let sheetRules = rules[sheetName]
    const compiledSheet = {}
    compiled[sheetName] = compiledSheet
    if (sheetRules.type === 'form') {
      delete sheetRules.columns
      sheetRules.columns = sheetRules.values
      compiledSheet.type = 'form'
    }
    if (!sheetRules.columns || !sheetRules.columns.length) {
      throw new Error(`Sheet '${sheetName}' has no defined columns/values in rulebook`)
    }
    compiledSheet.columns = sheetRules.columns.map(col => {
      if (typeof col === 'string') {
        const parsed = {
          key: null,
          type: null,
          required: false,
          default: null
        }
        const keyArr = col.split(':')
        // Default type if not defined
        if (keyArr.length === 1) {
          keyArr.push('STRING(256)')
        }
        parsed.key = keyArr[0]
        if (keyArr[1][0] === '!') {
          parsed.required = true
          keyArr[1] = keyArr[1].substr(1)
        }
        const typeArr = keyArr[1].split('?')
        if (typeArr.length > 1) {
          parsed.default = typeArr[1]
        }
        parsed.type = typeArr[0]

        col = parsed
      }

      // Parse type string into validator function
      const partArr = col.type.split('(')
      if (partArr.length === 1) {
        partArr.push('()')
      }
      if (partArr[1][partArr[1].length - 1] !== ')') {
        throw new Error(`Column Type syntax error. Missing closing bracket (${sheetName}.${col.key})`)
      }
      partArr[1] = partArr[1].substr(0, partArr[1].length - 1)
      const ops = []
      if (partArr[1][0] === '|') {
        ops.push('|')
        partArr[1] = partArr[1].substr(1)
      }
      const operands = partArr[1].split(',')
      ops.push(...operands)

      const vldtr = typeValidators[partArr[0]]
      if (typeof vldtr !== 'function') {
        throw new Error(`Unknown column type ${partArr[0]}`)
      }
      col.type = vldtr(sheetRules, col, ops)

      // Exchange required and default for function
      col.required = validateRequired(sheetRules, col, col.required)
      col.default = defaultValue(sheetRules, col, col.default)
      col.validate = (val, row, index) => {
        val = col.default(val, row, index)
        val = col.required(val, row, index)
        val = col.type(val, row, index)
        return val
      }
      return col
    })
    compiledSheet.computed = sheetRules.computed
    compiledSheet.enums = sheetRules.enums
    compiledSheet.virtual = sheetRules.virtual
  }
  return compiled
}

function validateRequired(def, col, required) {
  return (val, row, index) => {
    if (!required) {
      return val
    }
    if (!val) {
      throw new Error(`[Row ${index}, Column ${col.key}]: Value is required, but missing.`)
    }
    return val
  }
}

function defaultValue(def, col, defaultValue) {
  return (val, row) => {
    if (val) {
      return val
    }

    if (!defaultValue) {
      return val
    }

    if (typeof defaultValue === 'string') {
      if (defaultValue[0] === '#') {
        return def.virtual[defaultValue.substr(1)](row)
      }
    }
    return defaultValue
  }
}

function regexpCheck(def, col, ops, rexp, val, row, index) {
  if (!val && typeof val !== 'number') {
    return val
  }
  if (typeof val !== 'string') {
    val = val.toString()
  }
  if (!rexp.test(val)) {
    throw new Error(`[Row ${index}, Column '${col.key}'] contains non alphanumeric characters (${val})`)
  }
  if (ops[0] === '|') {
    if (ops.indexOf(val.length.toString()) < 0) {
      throw new Error(`[Row ${index}, Column '${col.key}'] value length is not in required constraints: '${ops.join(',')}' (${val})`)
    }
  } else if (ops && ops.length === 2) {
    if (val.length < parseInt(ops[0]) || val.length > parseInt(ops[1])) {
      throw new Error(`[Row ${index}, Column '${col.key}'] value length is not in required interval '${ops.join(' - ')}' (${val})`)
    }
  }
  return val
}

const typeValidators = {
  ASCIIU(def, col, ops) {
    if (ops.length === 1) {
      ops.splice(0,0,"0")
    }
    const rexp = /^[A-Za-z0-9_]*$/
    // check if contains only basic ASCI chars (alfanum)
    // ops can be length, | as first member means 'or'
    return regexpCheck.bind(null, def, col, ops, rexp)
  },
  ASCII(def, col, ops) {
    if (ops.length === 1) {
      ops.splice(0,0,"0")
    }
    const rexp = /^[A-Za-z0-9]*$/
    // check if contains only basic ASCI chars (alfanum)
    // ops can be length, | as first member means 'or'
    return regexpCheck.bind(null, def, col, ops, rexp)

    // return (val, row, index) => {
    //   if (!val && typeof val !== 'number') {
    //     return val
    //   }
    //   if (typeof val !== 'string') {
    //     val = val.toString()
    //   }
    //   if (!rexp.test(val)) {
    //     throw new Error(`[Row ${index}, Column '${col.key}'] contains non alphanumeric characters (${val})`)
    //   }
    //   if (ops[0] === '|') {
    //     if (ops.indexOf(val.length.toString()) < 0) {
    //       throw new Error(`[Row ${index}, Column '${col.key}'] value length is not in required constraints: '${ops.join(',')}' (${val})`)
    //     }
    //   } else if (ops && ops.length === 2) {
    //     if (val.length < parseInt(ops[0]) || val.length > parseInt(ops[1])) {
    //       throw new Error(`[Row ${index}, Column '${col.key}'] value length is not in required interval '${ops.join(' - ')}' (${val})`)
    //     }
    //   }
    //   return val
    // }
  },
  ENUM(def, col, ops) {
    // check if val is in given enum
    return (val, row, index) => {
      if (!val && typeof val !== 'number') {
        return val
      }
      const en = def.enums[ops[0]]
      if (en.indexOf(val) < 0) {
        throw new Error(`[Row ${index}, Column '${col.key}'] is not in enum values [${en.join(',')}] (${val})`)
      }
      return val
    }
  },
  UINT(def, col, ops) {
    if (ops.length === 1) {
      ops.splice(0,0,"0")
    }
    return (val, row, index) => {
      if (!val) {
        return val
      }
      if (typeof val !== 'number') {
        val = parseInt(val)
      }
      if (!Number.isInteger(val) || val < 0) {
        throw new Error(`[Row ${index}, Column '${col.key}'] is not unsigned integer (${val})`)
      }
      if (ops[0] === '|') {
        if (ops.indexOf(val.toString().length.toString()) < 1) {
          throw new Error(`[Row ${index}, Column '${col.key}'] count of digits is not in required constraints: '${ops.join(',')}' (${val})`)
        }
      } else if (ops && ops.length === 2) {
        if (val < parseInt(ops[0]) || val > parseInt(ops[1])) {
          throw new Error(`[Row ${index}, Column '${col.key}'] value is not in required interval '${ops.join(' - ')}' (${val})`)
        }
      }
      return val
    }
  },
  UFLOAT(def, col, ops) {
    if (ops.length === 1) {
      ops.splice(0,0,"0")
    }
    return (val, row, index) => {
      if (!val) {
        return val
      }
      if (typeof val !== 'number') {
        val = parseFloat(val)
      }
      if (ops && ops.length === 2) {
        if (val < parseFloat(ops[0]) || val > parseFloat(ops[1])) {
          throw new Error(`[Row ${index}, Column '${col.key}'] value is not in required interval '${ops.join(' - ')}' (${val})`)
        }
      }
      return val
    }
  },
  STRING(def, col, ops) {
    if (ops.length === 1) {
      ops.splice(0,0,"0")
    }
    return (val, row, index) => {
      if (!val && typeof val !== 'number') {
        return val
      }
      if (typeof val !== 'string') {
        val = val.toString()
      }
      if (ops[0] === '|') {
        if (ops.indexOf(val.length.toString()) < 1) {
          throw new Error(`[Row ${index}, Column '${col.key}'] length is not in required values '${ops.join(',')}' (${val})`)
        }
      } else if (ops && ops.length === 2) {
        if (val.length < parseInt(ops[0]) || val.length > parseInt(ops[1])) {
          throw new Error(`[Row ${index}, Column '${col.key}'] length is not in required interval '${ops.join(' - ')}' (${val})`)
        }
      }
      return val
    }
  },
  EAN(def, col, ops) {
    return (val, row, index) => {
      if (!val && typeof val !== 'number') {
        return null
      }
      if (typeof val !== 'string') {
        val = val.toString()
      }
      if (val.length < 6 || val.length > 16) {
        throw new Error(`[Row ${index}, Column '${col.key}'] EAN is not in required interval '6 - 16 characters' (${val})`)
      }
      return parseInt(val)
    }
  },
  EANS(def, col, ops) {
    return (val, row, index) => {
      if (!val && typeof val !== 'number') {
        return null
      }
      if (typeof val !== 'string') {
        val = val.toString()
      }
      return val.split('-').map(res => {
        if (res.length < 6 || res.length > 16) {
          throw new Error(`[Row ${index}, Column '${col.key}'] EAN is not in required interval '6 - 16 characters' (${val})`)
        }
        return parseInt(res)
      })
    }
  },
  PRODKOD(def, col, ops) {
    return (val, row, index) => {
      if (!val && typeof val !== 'number') {
        return null
      }
      if (typeof val !== 'string') {
        val = val.toString()
      }
      const re = /^[A-Za-z0-9]{1,5}\.[A-Za-z0-9]{1,5}\.[A-Za-z0-9]{1,5}\.[A-Za-z0-9]{1,8}\.[A-Za-z0-9]{1,3}$/
      if (!val.match(re)) {
        throw new Error(`[Row ${index}, Column '${col.key}'] Product Code is not in required format (${val})`)
      }

      return val
    }
  },
  DATE(def, col, ops) {
    return (val, row, index) => {
      if (!val && typeof val !== 'number') {
        return null
      }
      let res = null
      if (typeof val === 'number') {
        res = excelSerialDateToJSDate(val)
      } else if (typeof val !== 'string') {
        val = val.toString()
        res = new Date(val)
      }
      if (!(res instanceof Date)) {
        throw new Error(`[Row ${index}, Column '${col.key}'] Date input is not convertible (${val})`)
      }
      return  res
    }
  }
}

function excelSerialDateToJSDate(serial) {
   var utc_days  = Math.floor(serial - 25569);
   var utc_value = utc_days * 86400;
   var date_info = new Date(utc_value * 1000);

   var fractional_day = serial - Math.floor(serial) + 0.0000001;

   var total_seconds = Math.floor(86400 * fractional_day);

   var seconds = total_seconds % 60;

   total_seconds -= seconds;

   var hours = Math.floor(total_seconds / (60 * 60));
   var minutes = Math.floor(total_seconds / 60) % 60;

   return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}
