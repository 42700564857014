import XLSX from 'xlsx';
import { compileInRules } from './compileRules.js'
import workbookToJson from './workbookToJson.js'

export async function parseFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function(e) {
      try {
        const workbook = XLSX.read(reader.result, {type: 'binary'})
        resolve(workbook)
      } catch (e) {
        reject(e)
      }
    }
    reader.onerror = reject
    reader.readAsBinaryString(file)
  })
}

export default {
  compileInRules,
  parseFile,
  workbookToJson
}
