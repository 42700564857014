<template>
  <div class="zone">
    <Message
      severity="error"
      v-show="error"><b>Parsing error:</b> {{error}}</Message>
    <div class="droparea"
      v-on:drop="dropHandler"
      v-on:dragover="dragHandler">
      <div class="upload" v-show="!file">
        <i class="pi pi-file-excel"></i>
        <p class="big">Drop product catalog here</p>
        <p class="small">or</p>
        <FileUpload
          mode="basic"
          chooseLabel="Browse"
          accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :customUpload="true"
          :auto="true"
          @uploader="processHandler" />
      </div>
      <div class="progress" v-show="file">
        <p style="text-align:center;">Parsing file...</p>
        <ProgressSpinner />
      </div>
    </div>
  </div>
</template>

<script>

//import XLSX from 'xlsx';
import xlsxParser from '../service/xlsxParser/xlsxParser.js'

export default {
  props: [
    'rules'
  ],
  data() {
    return {
      loadedRules: null,
      file: null,
      error: null
    }
  },

  methods: {
    async dropHandler(evt) {
      evt.preventDefault()

      this.processHandler(evt.dataTransfer)
    },

    dragHandler(evt) {
      evt.preventDefault()
    },

    async processHandler(evt) {
      if (!evt.files || !evt.files.length) {
        this.error = 'Dropped object is not an Excel Sheet File.'
        return
      }

      this.file = evt.files[0]
      this.loadedRules = this.rules

      try {
        if (typeof this.rules === 'function') {
          this.loadedRules = await this.rules()
        }

        this.compiledRules = xlsxParser.compileInRules(this.loadedRules)
        const workbook = await xlsxParser.parseFile(this.file)
        const json = xlsxParser.workbookToJson(workbook, this.compiledRules)

        this.file = null
        this.error = null
        this.$emit('parsed', json)
      } catch (e) {
        console.log(e)
        this.error = e
        this.file = null
        // Errors:
        // - not an excel sheet (unparsable)
        // - rules compile error
      }
    }
  }
}

</script>

<style scoped>
.droparea {
  border: 1px dashed var(--surface-500);
  border-radius: 1rem;
  width: 100%;
  padding: 1.5rem;
  text-align: center;
  color: var(--surface-600);
}

.droparea .upload i.pi {
  font-size: 2rem;
}

.droparea .upload p.big {
  font-size: 2rem;
}

.droparea .progress {
  padding-top: .54rem;
  padding-bottom: .54rem;
}

</style>
