<template>
  <div class="row-status">
    <div class="badge ok" v-show="status === 'new'" v-tooltip="'New, to import.'">
      <i class="pi pi-check"></i>
    </div>
    <div class="badge duplicit" v-show="status === 'duplicit'" v-tooltip="'Duplicit, won\'t import.'">
      <i class="pi pi-clone"></i>
    </div>
    <div class="badge
      update" :class="{ selected: row._selected }"
      v-show="status === 'update'"
      @click="updateClicked"
      v-tooltip="'Exists in Flexi, but changed. Select if to import.'">
      <i class="pi pi-pencil"></i>
    </div>
    <div class="badge error"
      v-show="status === 'error'"
      @click="toggleErrorList"
      v-tooltip="'Error. Click to find more.'">
      <i class="pi pi-times"></i>
    </div>
    <OverlayPanel ref="op">
      <ul>
        <li v-for="error in row._errors" :key="error">{{error.message}}</li>
      </ul>
    </OverlayPanel>
  </div>
</template>

<script>

export default {
  props: ['row'],
  emit: ['toggleUpdate'],
  data() {
    return {
      checked: true
    }
  },
  computed: {
    status() {
      if (this.row._status === 'E') {
        return 'error'
      } else if (this.row._status === 'D') {
        return 'duplicit'
      } else if (this.row._status === 'U') {
        return 'update'
      }
      return 'new'
    },
    selected() {
      return false
    }
  },
  methods: {
    updateClicked() {
      this.$emit('toggleUpdate', this.row)
    },

    toggleErrorList(event) {
      this.$refs.op.toggle(event)
    }
  }
}
</script>

<style scoped>

.row-status .badge {
  padding: 0rem 0.4rem;
  border-radius: 1rem;
  width: 1.4rem;
  text-align: center;
  vertical-align: middle;
  position: relative;
}

.row-status .badge.ok {
  border: 1px solid var(--green-500);
  color: var(--green-500);
}

.row-status .badge.duplicit {
  border: 1px solid var(--bluegray-500);
  color: var(--bluegray-500);
}

.row-status .badge.duplicit i {
  top: -1px;
  font-weight: normal;
}

.row-status .badge.update {
  border: 1px solid var(--yellow-600);
  color: var(--yellow-600);
  cursor: pointer;
}

.row-status .badge.update.selected {
  background-color: var(--yellow-600);
  color: var(--primary-color-text);
}

.row-status .badge.update i {
  font-weight: normal;
}

.row-status .badge.error {
  border: 1px solid var(--orange-700);
  color: var(--orange-700);
  cursor: pointer;
}

.row-status .badge.error i {
  font-weight: normal;
}

.row-status .badge i {
  font-size: 0.8rem;
  font-weight: bold;
  position: relative;
  left: -2px;
  top: 0px;
}

.p-checkbox {
  width: 15px;
  height: 15px;
}

.p-checkbox > * {
  width: 15px;
  height: 15px;
}

.p-checkbox-icon {
  font-siize: 10px;
}

</style>
